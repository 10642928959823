<template>
  <div>
    <v-row align="center" class="mt-8" justify="center">
      <v-col class="mt-3 pl-8 pr-8" cols="12">
        <v-card elevation="2" max-width="100%" cols="12">
          <v-row>
            <v-col cols="8">
              <v-file-input
                class="ml-5"
                :rules="rules"
                accept=".xlsx"
                show-size
                prepend-icon="mdi-format-list-group-plus"
                label="Cargue el archivo de usuarios a consolidar"
              ></v-file-input>
            </v-col>
            <v-col cols="4">
              <a href="/files/PLANTILLA_CONSOLIDADO.xlsx" download>
                <v-chip class="mt-5 white--text" color="#5cbbf6">
                  Descargar plantilla
                </v-chip>
              </a>
            </v-col>
            <!-- <p class="text-title-l">
                        Elige el pilar para el que desea la asesoría
                    </p> -->
          </v-row>
          <v-row justify="center" cols="12">
            <v-col cols="8">
              <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading"
                color="info"
                @click="loader = 'loading'"
              >
                Generar archivo consolidado
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      align="center"
      max-width="1000px"
      class="mt-8"
      justify="center"
      v-if="flag"
    >
      <v-col class="mt-3 pl-8 pr-8" cols="12">
        <v-card elevation="2" max-width="1000px" cols="12">
          <v-row>
            <vue-table
              v-model="products"
              :headers="headers"
              :custom-options="customOptions"
              :style-wrap-vue-table="styleWrapVueTable"
              :disable-cells="disableCells"
              :disable-sort-thead="disableSortThead"
              :loading="loading"
              :parent-scroll-element="parentScrollElement"
              :select-position="selectPosition"
              :submenu-tbody="submenuTbody"
              :submenu-thead="submenuThead"
              @tbody-checked-row="checkedData"
              @tbody-all-checked-row="checkedAllData"
              @tbody-change-data="changeData"
              @tbody-submenu-click-change-color="changeColorTbody"
              @tbody-submenu-click-change-value="changeValueTbody"
              @thead-submenu-click-change-color="changeColorThead"
              @thead-submenu-click-change-value="changeValueThead"
              @thead-td-sort="sortProduct"
            >
              <div slot="header">Specific Header</div>
              <div slot="loader">Loader</div>
            </vue-table>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import vueShowMoreText from "vue-show-more-text";
import VueTable from "vuejs-spreadsheet";
import Api from "@/utils/api";

export default {
  components: {
    vueShowMoreText,
    VueTable,
  },
  name: "VueSpreadsheet",
  data() {
    return {
      listProjects: [],
      loader: null,
      loading: false,
      flag: false,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "El archivo debe pesar menos de 2 MB!",
      ],
      products: [
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          c: {
            type: "input",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec aliquam magna. Sed consequat amet..",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#eee",
            },
            selectOptions: [
              {
                value: "Harry Potter",
                label: "harry potter",
              },
              {
                value: "Hermione Granger",
                label: "hermione granger",
              },
              {
                value: "Ron Whisley",
                label: "ron whisley",
              },
              {
                value: "Dobby",
                label: "dobby",
              },
              {
                value: "Hagrid",
                label: "hagrid",
              },
              {
                value: "Professeur Rogue",
                label: "professeur rogue",
              },
              {
                value: "Professeur Mcgonagal",
                label: "professeur mcgonagal",
              },
              {
                value: "Professeur Dumbledor",
                label: "professeur dumbledor",
              },
            ],
            value: "professeur dumbledor",
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#eee",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#eee",
            },
            selectOptions: [
              {
                value: "Harry Potter",
                label: "harry potter",
              },
              {
                value: "Hermione Granger",
                label: "hermione granger",
              },
              {
                value: "Ron Whisley",
                label: "ron whisley",
              },
              {
                value: "Dobby",
                label: "dobby",
              },
              {
                value: "Hagrid",
                label: "hagrid",
              },
              {
                value: "Professeur Rogue",
                label: "professeur rogue",
              },
              {
                value: "Professeur Mcgonagal",
                label: "professeur mcgonagal",
              },
              {
                value: "Professeur Dumbledor",
                label: "professeur dumbledor",
              },
            ],
            value: "Harry Potter",
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#eee",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: "Harry Potter",
                label: "harry potter",
              },
              {
                value: "Hermione Granger",
                label: "hermione granger",
              },
              {
                value: "Ron Whisley",
                label: "ron whisley",
              },
              {
                value: "Dobby",
                label: "dobby",
              },
              {
                value: "Hagrid",
                label: "hagrid",
              },
              {
                value: "Professeur Rogue",
                label: "professeur rogue",
              },
              {
                value: "Professeur Mcgonagal",
                label: "professeur mcgonagal",
              },
              {
                value: "Professeur Dumbledor",
                label: "professeur dumbledor",
              },
            ],
            value: "Hermione Granger",
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: "Harry Potter",
                label: "harry potter",
              },
              {
                value: "Hermione Granger",
                label: "hermione granger",
              },
              {
                value: "Ron Whisley",
                label: "ron whisley",
              },
              {
                value: "Dobby",
                label: "dobby",
              },
              {
                value: "Hagrid",
                label: "hagrid",
              },
              {
                value: "Professeur Rogue",
                label: "professeur rogue",
              },
              {
                value: "Professeur Mcgonagal",
                label: "professeur mcgonagal",
              },
              {
                value: "Professeur Dumbledor",
                label: "professeur dumbledor",
              },
            ],
            value: "Professeur Rogue",
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: "Harry Potter",
                label: "harry potter",
              },
              {
                value: "Hermione Granger",
                label: "hermione granger",
              },
              {
                value: "Ron Whisley",
                label: "ron whisley",
              },
              {
                value: "Dobby",
                label: "dobby",
              },
              {
                value: "Hagrid",
                label: "hagrid",
              },
              {
                value: "Professeur Rogue",
                label: "professeur rogue",
              },
              {
                value: "Professeur Mcgonagal",
                label: "professeur mcgonagal",
              },
              {
                value: "Professeur Dumbledor",
                label: "professeur dumbledor",
              },
            ],
            value: "Dobby",
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: "Harry Potter",
                label: "harry potter",
              },
              {
                value: "Hermione Granger",
                label: "hermione granger",
              },
              {
                value: "Ron Whisley",
                label: "ron whisley",
              },
              {
                value: "Dobby",
                label: "dobby",
              },
              {
                value: "Hagrid",
                label: "hagrid",
              },
              {
                value: "Professeur Rogue",
                label: "professeur rogue",
              },
              {
                value: "Professeur Mcgonagal",
                label: "professeur mcgonagal",
              },
              {
                value: "Professeur Dumbledor",
                label: "professeur dumbledor",
              },
            ],
            value: "Professeur Dumbledor",
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 26,
                label: 26,
              },
              {
                value: 27,
                label: 27,
              },
              {
                value: 28,
                label: 28,
              },
              {
                value: 29,
                label: 29,
              },
              {
                value: 30,
                label: 30,
                active: true,
              },
            ],
            value: 30,
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 26,
                label: 26,
              },
              {
                value: 27,
                label: 27,
              },
              {
                value: 28,
                label: 28,
              },
              {
                value: 29,
                label: 29,
              },
              {
                value: 30,
                label: 30,
                active: true,
              },
            ],
            value: 30,
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 26,
                label: 26,
              },
              {
                value: 27,
                label: 27,
              },
              {
                value: 28,
                label: 28,
              },
              {
                value: 29,
                label: 29,
              },
              {
                value: 30,
                label: 30,
                active: true,
              },
            ],
            value: 30,
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 26,
                label: 26,
              },
              {
                value: 27,
                label: 27,
              },
              {
                value: 28,
                label: 28,
              },
              {
                value: 29,
                label: 29,
              },
              {
                value: 30,
                label: 30,
                active: true,
              },
            ],
            value: 30,
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
        {
          a: {
            type: "img",
            value: "https://via.placeholder.com/350x150",
            active: false,
          },
          b: {
            type: "input",
            value: "Jane",
            active: false,
            style: {
              color: "#000",
            },
          },
          c: {
            type: "input",
            value: "Paris",
            active: false,
            style: {
              color: "#000",
            },
          },
          d: {
            type: "input",
            value: "France",
            active: false,
            style: {
              color: "#000",
            },
          },
          e: {
            type: "input",
            value: "Boe",
            active: false,
            style: {
              color: "#000",
            },
          },
          f: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 26,
                label: 26,
              },
              {
                value: 27,
                label: 27,
              },
              {
                value: 28,
                label: 28,
              },
              {
                value: 29,
                label: 29,
              },
              {
                value: 30,
                label: 30,
                active: true,
              },
            ],
            value: 30,
            active: false,
          },
          g: {
            type: "select",
            handleSearch: true,
            comment: {
              value: "comment",
              borderColor: "#000",
            },
            selectOptions: [
              {
                value: 1980,
                label: 1980,
              },
              {
                value: 1981,
                label: 1981,
              },
              {
                value: 1982,
                label: 1982,
              },
              {
                value: 1983,
                label: 1983,
                active: true,
              },
              {
                value: 1984,
                label: 1984,
              },
            ],
            value: 1983,
            active: false,
          },
        },
      ],
      headers: [
        {
          headerName: "A",
          headerKey: "a",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
        {
          headerName: "B",
          headerKey: "b",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
        {
          headerName: "C",
          headerKey: "c",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
        {
          headerName: "D",
          headerKey: "d",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
        {
          headerName: "E",
          headerKey: "e",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
        {
          headerName: "F",
          headerKey: "f",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
        {
          headerName: "G",
          headerKey: "g",
          style: {
            width: "200px",
            minWidth: "200px",
            color: "#000",
          },
        },
      ],
      customOptions: {
        tbodyIndex: true,
        tbodyCheckbox: true,
        sortHeader: true,
        trad: {
          lang: "fr",
          en: {
            select: {
              placeholder: "Search by typing",
            },
          },
          fr: {
            select: {
              placeholder: "Taper pour chercher",
            },
          },
        },
        newData: {
          type: "input",
          value: "",
          active: false,
          style: {
            color: "#000",
          },
        },
        fuseOptions: {
          shouldSort: true,
          threshold: 0.2,
          location: 0,
          distance: 30,
          maxPatternLength: 64,
          minMatchCharLength: 1,
          findAllMatches: false,
          tokenize: false,
          keys: ["value"],
        },
      },
      styleWrapVueTable: {
        height: "400px",
        overflow: "visible",
        fontSize: "12px",
        comment: {
          borderColor: "#696969",
          borderSize: "8px",
          widthBox: "120px",
          heightBox: "80px",
        },
      },
      disableCells: ["a"],
      disableSortThead: ["a"],
      parentScrollElement: {
        attribute: "html",
        positionTop: 0,
      },
      selectPosition: {
        top: 0,
        left: 0,
      },
      submenuTbody: [
        {
          type: "button",
          value: "change color",
          function: "change-color",
          disabled: ["img"],
        },
        {
          type: "button",
          value: "change value",
          function: "change-value",
          disabled: ["img", "name"],
        },
      ],
      submenuThead: [
        {
          type: "button",
          value: "change color",
          function: "change-color",
          disabled: ["a"],
        },
        {
          type: "select",
          disabled: ["a"],
          subtitle: "Select state:",
          selectOptions: [
            {
              value: "new-york",
              label: "new-york",
            },
            {
              value: "france",
              label: "france",
            },
          ],
          value: "new-york",
          buttonOption: {
            value: "change city",
            function: "change-city",
            style: {
              display: "block",
            },
          },
        },
        {
          type: "button",
          value: "change value",
          function: "change-value",
          disabled: ["a", "b"],
        },
      ],
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      let app = this;

      setTimeout(() => (this[l] = false), 3000);
      this.flag = true;
      this.loader = null;
    },
  },
  methods: {
    openDescription(data) {
      this.$emit("open");
      this.$emit("info", data);
    },
    getControl() {
      Api.noAuth()
        .getControl()
        .then((resp) => resp.json())
        .then((data) => {
          data.forEach((app) => {
            this.listProjects.push(app);
          });
          // console.log(`Pilares -- ${JSON.stringify(this.listProjects)}`)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadTemplateFile() {
      alert("mierda");
    },
    checkedAllData(isChecked) {
      console.log("checked all data", isChecked);
    },
    checkedData(row) {
      console.log("checked data", row);
    },
    changeData(row, header) {
      console.log(row, header);
    },
    sortProduct(event, header, colIndex) {
      console.log("sort product");
    },
    // callback
    changeColorThead(event, header, colIndex) {
      this.headers[colIndex].style.color = "#e40000";
    },
    changeValueThead(event, header, colIndex) {
      this.headers[colIndex].headerName = "T-shirt";
    },
    changeColorTbody(event, header, rowIndex, colIndex) {
      this.products[rowIndex][header].style = {};
      this.products[rowIndex][header].style.color = "#e40000";
    },
    changeValueTbody(event, header, rowIndex, colIndex) {
      this.products[rowIndex][header].value = "T-shirt";
    },
  },
  mounted() {
    //this.getControl()
  },
};
</script>
<style>
.border-control-card {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0 !important;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
