<template>
  <div>
    <v-row>
      <v-col class="mt-3 pl-8 pr-8" cols="12">
        <v-img src="../../assets/img/life-control-banner.png">
          <p
            class="text-ligth-sub text-left ml-8 mb-7"
            style="padding-top: 10%"
          >
            Bienvenido al módulo de
          </p>
          <p class="text-title-ligth text-left ml-8 mb-8">
            Informe consolidado
          </p>
        </v-img>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="7"> </v-col>
      <v-col cols="2">
        <v-btn rounded outlined dark color="#3957b9" @click="download()">
          descargar plantilla
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn rounded dark class="btn-main ml-3" @click="openModel()">
          subir archivo
        </v-btn>
      </v-col>
    </v-row>

    <!-- modal temporal -->
    <!-- <v-dialog v-model="modalSend" justify="center" max-width="400">
      <v-card>
        <v-card-title style="justify-content:center">
          <h3 style="color:#141B33">Hemos enviado tu solicitud</h3>
        </v-card-title>
        <v-card-text>
          <p>Pronto te contactara tu padrino para asesorarte en tu solicitud</p>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- fin  modal temporal -->
    <v-row class="mt-8" justify="center">
      <v-col cols="8">
        <p class="mb-10 text-title-xl">
          Elija el pilar para el que desea la asesoría
        </p>
        <LoadBaseFile
          class="v-step-16"
          @open="modal.projectM = true"
        ></LoadBaseFile>
      </v-col>
    </v-row>
    <!-- <ModalDescription @close="modal.projectM = false" :modal="modal.projectM" :infoProject="info" @data="setReason($event)">
    </ModalDescription> -->
    <!--     <ModalQuestion
      @data="setDataInitial($event)"
      @close="modal.open = false"
      :open="modal.open"
    ></ModalQuestion> -->
  </div>
</template>
<script>
import LoadBaseFile from "./LoadBaseFile";
//import ModalDescription from "./ModalDescription";
//import Projects from "./Projects";
import Api from "@/utils/api";

export default {
  components: {
    LoadBaseFile,
    //ModalQuestion,
    //Projects,
  },
  data() {
    return {
      modalSend: false,
      info: {},
      infoToken: {
        document: "",
      },
      modal: {
        open: false,
        projectM: false,
      },
      data: {
        description: "",
        lifeprojectid: "",
        date: "",
        contractorid: "",
        userid: "",
        useremail: "",
      },
    };
  },
  methods: {
    getInfo(e) {
      this.info = e;
    },
    setDataInitial(e) {
      this.data.contractorid = e.company;
      this.data.date = e.dateContract;
    },
    setReason(e) {
      this.data.description = e;
      this.data.lifeprojectid = this.info.id.toString();
      this.save();
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    download() {
      window.open("/files/PLANTILLA_CONSOLIDADO.xlsx", "_blank");
    },
    save() {
      this.data.userid = this.infoToken.document;
      this.data.useremail = this.infoToken.email;
      this.data.contractorid = sessionStorage.getItem("contractor");
      this.data.date = sessionStorage.getItem("dateAdmision");

      // console.log(`datos prueba -- ${JSON.stringify(this.data)}`);
      Api.noAuth()
        .save(this.data)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.cod == 0) {
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // console.log(this.data);
      this.modal.projectM = false;
      this.modalSend = true;
    },
  },
  mounted() {
    this.getToken();
    this.modal.open = true;
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
</style>
